import React, {FC, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage, TopProgress} from "../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useUi } from "../../context/ui.context";
import { networkService } from "../../api";
import {
  StateWrapper,
  GreenDot,
  Greentext
} from './styles'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { randomNameGenerator } from "../../utils/create-random-name";
import { useAuth } from "../../context/user.context";
import { handleStatusName } from "../../utils/handle-status-name";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";


  
const Networks:FC = () => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [editedSubnet, setEditedSubnet] = useState<any>()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const {user, quotas} = useAuth()
  const [networks, setNetworks] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [deletedNetwork, setDeletedNetwork] = useState<any>()
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [paginationData, setPaginationData] = useState<any>()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      setAllChecked(false)
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(networks?.filter((row:any) => row.name !== 'external_network' && row.name !== 'external_snovio'), checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const updateNetworks = () => {
    // setLoading(true)
    networkService.getAllNetworks({
      page:page||1,
      ordering,
      pageSize:pageSize||10
    })
    .then((res) => {
      setPaginationData(res.data)
      setNetworks(res.data.objects.map((network:any) => {
        return {
          ...network,
          name:network.name,
          navigate:network.id,
          hideProps:network?.name === 'external_network' || network.name === 'external_snovio',
          hideCheckbox:network?.name === 'external_network' || network.name === 'external_snovio',
          status: 
          (<StateWrapper>
            <GreenDot active={network.status === "ACTIVE"}/>
            <Greentext active={network.status === "ACTIVE"}>
              {handleStatusName(network.status, t)}
            </Greentext>
          </StateWrapper>) ,
          subnets:network?.subnets?.map((subnet:any) => subnet.name),
          region:network.region,
        }
      }))
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      // navigate('/home')
      console.log('e', e);
    })
    .finally(() => {
      setPageLoading(false)
      setLoading(false)
    })
  }
  useEffect(() => {
    updateNetworks()
    const interval = setInterval(() => {
      updateNetworks()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[page, ordering, pageSize])
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (id:any) => navigate(`edit/${id.id}`),
      divider:false
    },
    {
      title:t("ADD_SUBNET"),
      id:'3',
      icon:icons.PlusDefault,
      onClick:(itemNetwork:any) => {
        setEditedSubnet({
          name:randomNameGenerator({name:'subnet', findArr:[]}),
          cidr:`10.0.${itemNetwork.subnets.length + 2}.0/24`,
          ip_version:'4',
          setGatewayIp: "default",
          enable_dhcp:true,
          dns_nameservers:[],
          host_routes:[],
          network_id:itemNetwork.id,
          _id:randomNameGenerator({name:'', findArr:[]}),
          network_mask:0,
          network_address_source:'manually',
          allocation_pools:[],
    
          //data we don't have fields
          ipv6_ra_mode:null,
          ipv6_address_mode:null,
          gateway_ip:'',
      })
        openModal('create-subnet')},
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(id:any) => {
        openModal('delete-networks')
        setAlertTitle(t("NETWORK_TO_DELETE"))
        setAlertSubTitle([id])
        setDeletedNetwork(id)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]

  const closeModal = (props:any) => {
    if(modalType === 'create-subnet') {
      setLoading(true)
      networkService.createSubnet({
        ...props,
      })
      .then((res) => {
        updateNetworks()
        setLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
        viewAlert({
          severity:'info',
          message:`${t("SUBNET_ADDED_SUCCESSFULLY")}`
        })
      })
      .catch((e) => {
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail
          })
        }
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
      setAlertSubTitle([])
      setChecked([])
      updateNetworks()
    }

  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("NETWORK_STATE"),
      key:'status',
      sorted:false
    },
    {
      title:t("SUBNETS"),
      key:'subnets',
      sorted:false
    },
    // {
    //   title:t("REGION"),
    //   key:'region',
    //   sorted:true
    // },
    // {
    //   title:'Description',
    //   key:'description',
    //   sorted:false
    // },
    {
      title:'',
      key:'props',
      sorted:false
    },
  ]
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas.network.network.limit === quotas.network.network.in_use){
      viewAlert({
        severity:'error',
        message:`${t('NETWORK_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('create')
    }
  }
  return (
    <>
      <TopProgress loading={pageLoading || loading}/>
      <StartPage
        title={`${t("NETWORKS")}`}
        noResourceText={`${t("YOU_HAVE_NO_NETWORKS")}`}
        createResourceButton={`${t('CREATE_NETWORK')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={networks?.length > 0}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/networks`}
        learnMoreAboutResource={`${t('LEARN_MORE_NETWORKS')}`}
        howCreateVideoText={`${t('HOW_CREATE_NETWORK')}`}
        howCreateVideoUrl="https://www.youtube.com/embed/OJfWVeL-3tc"
        videoImage={Illustration}
        pageLoading={pageLoading}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("NETWORKS"))
                setAlertSubTitle(allChecked ? networks.filter((net:any) => net.name !== 'external_network' && net.name !== 'external_snovio') : checked)
                openModal('delete-networks')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked || checked?.filter((net:any) => net?.name !== 'external_network' && net.name !== 'external_snovio')?.length === 0}
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        videoImageDark={IllustrationDark}
        tableData={{
          columns:Columns,
          data:networks,
          props:items,
          loading:loading,
          updateData:updateNetworks,
          paginationData:paginationData,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
        }}
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        loading={loading}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedSubnet}
      />
    </>
  )
}



export default Networks
