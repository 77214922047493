import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUi } from './ui.context';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { formatCurrency, formatCurrencyString } from '../utils/display-price';
import { instanceService, kubernetesService } from '../api';
import { handleOsIcons } from '../hooks/useOsIcons';

export const KubernetesContext = createContext<KubernetesContextProps>({} as KubernetesContextProps);

interface KubernetesContextProps {
  clusterTypes:any[],
  selectedClusterType:any,
  setSelectedClusterType:any,
  clusterData:any,
  setClusterData:any,
  nodePoolsData:any,
  setNodePoolsData:any,
  pageKuberLoading:any,
  setPageKuberLoading:any,
  kuberVersions:any[],
  networks:any[],
  networkingData:any,
  setNetworkingData:any,
  addOns:any,
  setAddOns:any,
  addOnsList:any[],
}

type ContextProps = {
  children?:any
}

const KubernetesContextProvider: React.FC<ContextProps> = (props) => {
  const clusterTypes = [
    {
      masterCount:1,
      nodesCount:1,
      vCpus:2,
      ram:4,
      storage:30,
      vCpusWorker:2,
      ramWorker:8,
      storageWorker:50,
      id:1,
      title:'DEV_TITLE',
      description:'DEV_INFO',
      kuber_version:'v1.27.4',
      os:'ubuntu-22.04-kube-v1.27.4'
    },
    {
      masterCount:1,
      nodesCount:3,
      vCpus:3,
      ram:6,
      storage:60,
      id:2,
      title:'STAGING_TITLE',
      description:'STAGING_INFO',
      kuber_version:'v1.27.4',
      os:'ubuntu-22.04-kube-v1.27.4'
    },
    {
      masterCount:3,
      nodesCount:3,
      vCpus:3,
      ram:16,
      storage:160,
      id:3,
      title:'PRODUCTION_TITLE',
      description:'PRODUCTION_INFO',
      kuber_version:'v1.27.4',
      os:'ubuntu-22.04-kube-v1.27.4'
    },
    {
      masterCount:1,
      nodesCount:1,
      id:4,
      isIndividual:true,
      title:'INDIVIDUAL_TITLE',
      description:'INDIVIDUAL_INFO',
      kuber_version:'v1.27.4'
    },
  ]

  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {getDataToLs} = useLocalStorage()
  const [pageKuberLoading, setPageKuberLoading] = useState<boolean>(true)
  const [selectedClusterType, setSelectedClusterType] = useState<any>(null)
  const [clusterData, setClusterData] = useState<any>()
  const [nodePoolsData, setNodePoolsData] = useState<any[]>([])
  const [networkingData, setNetworkingData] = useState<Object>({})
  const [kuberVersions, setKuberVersions] = useState<any[]>([])
  const [networks, setNetworks] = useState<any[]>([])
  const [addOns, setAddOns] = useState<any[]>(getDataToLs({key:'cluster.add-ons'}) || []
  //   {
  //   nginx:getDataToLs({key:'cluster.add-ons'})?.nginx || false,
  //   metrics:getDataToLs({key:'cluster.add-ons'})?.metrics || false,
  // }
  )
  const [addOnsList, setAddOnsList] = useState<any>()
  //saved state

  //create options
  //@ts-ignore
  const compareVersions = (a, b) => {
    const aParts = a.version.slice(1).split('.').map(Number);
    const bParts = b.version.slice(1).split('.').map(Number);
  
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = aParts[i] || 0;
      const bPart = bParts[i] || 0;
  
      if (aPart > bPart) return -1; // Измените на -1 для сортировки по убыванию
      if (aPart < bPart) return 1; // Измените на 1 для сортировки по убыванию
    }
  
    return 0;
  };
  const gerCreateOptions = async () => {
    try{
      const networks = await kubernetesService.getNetworks()
      const kuberVersions = await kubernetesService.getVersions()
      const addons = await kubernetesService.getAddons()
      await setAddOnsList(addons.data?.map((addon:any) => {
        return{
          ...addon,
          release:addon?.releases?.[0],
          releases:addon?.releases?.map((release:any) => {
            return{
              label:release.version,
              value:release.version,
            }
          })
        }
      }))
      await setKuberVersions(kuberVersions?.data?.sort(compareVersions).map((version:any) => {
        return {
          images: version.images.map((item:any) => {
            return {
              ...item,
              value: item.image_name,
              label: item.name,
              icon: handleOsIcons(item.os_distro.toLowerCase())
            }
          } ),
          label:version.version,
          value:version.version,

        }
      }))
      await setNetworks(networks?.data.map((network:any) => {
        return {
          ...network,
          label:network.network_name,
          value:network.id,
        }
      }))
      await setPageKuberLoading(false)
    } catch (error:any) {
      await setPageKuberLoading(false)
      if(error?.response?.status >= 500){
        navigate('/kubernetes')
        viewAlert({
          severity:'error',
          message:`${t("CLUSTER_SERVICE_UNAVAILABLE")}`
        })
      } else {
        viewAlert({
          severity:'error',
          message:error?.response?.data?.message || error?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
      }
    }
  }

  useEffect(() => {
    gerCreateOptions()
  },[])
  return (
    <KubernetesContext.Provider 
      value={{
        clusterTypes,
        selectedClusterType,
        setSelectedClusterType,
        clusterData,
        setClusterData,
        nodePoolsData,
        setNodePoolsData,
        pageKuberLoading,
        setPageKuberLoading,
        kuberVersions,
        networks,
        networkingData,
        setNetworkingData,
        addOns,
        setAddOns,
        addOnsList
      }}
      {...props}
    />
  );
};

const useKubernetes = () => {
  const context = React.useContext(KubernetesContext);
  if (context === undefined) {
    throw new Error('useKubernetes must be used within a KubernetesContextProvider');
  }
  return context as KubernetesContextProps;
};

export { KubernetesContextProvider, useKubernetes };


