export const formatFileSize = (bytes?:any,decimalPoint?:any) => {
  if(bytes == 0) return '0 Bytes';
  var k = 1024,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}
export const formatFileTrafficSize = (bits: any, decimalPoint?: number) => {
  if (bits === 0) return '0 Bits';
  var k = 1024,
    dm = decimalPoint || 2,
    sizes = ['bits','Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'],
    i = Math.floor(Math.log(bits / 8) / Math.log(k));
  return parseFloat(((bits / 8) / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}
export const formatTrafficSize = (bitsPerSecond?: any, decimalPoint?: any) => {
  if(bitsPerSecond == 0) return '0 bps';
  var k = 1024, // 1 Kbps = 1000 bps
      dm = decimalPoint || 2,
      sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'],
      i = Math.floor(Math.log(bitsPerSecond) / Math.log(k));
  return parseFloat((bitsPerSecond / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatQuotasRam = (bytes?:any,decimalPoint?:any) => {
  if(bytes == 0) return '0 Bytes';
  var k = 1024,
      dm = decimalPoint || 1,
      sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export const formatQuotasStorage = (bytes?:any,decimalPoint?:any) => {
  if(bytes == 0) return '0 Bytes';
  var k = 1000,
      dm = decimalPoint || 1,
      sizes = ['GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

export const formatStorageMB = (bytes?:any,decimalPoint?:any) => {
  if(bytes == 0) return '0 Bytes';
  var k = 1000,
      dm = decimalPoint || 1,
      sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}