import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useMatch } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../components";
import {
  Container,
  InstanceName,
  NavWrapper,
} from './styles'
import {
  MyProfile,
  Personalization,
  Security,
  GoogleAuth,
  ApiAccessPage
} from './components'
import { authService } from "../../api";
import { useUi } from "../../context/ui.context";


const AccountSettings:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState(false)
  const [activeTab, setActiveTab] = useState<string>('1')
  const location = useLocation()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [googleAuth, setGoogleAuth] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModal = (values:any) => {
    if(modalType === 'confirm-password') {
      setLoading(true)
      authService.confirmPasswordSfa(values)
      .then((res:any) => {
        navigate(`security`)
        setActiveTab('2')
      })
      .catch((e:any) => {
        if(e.response.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        } 
      })
      .finally(() => {
        setViewModal(false)
        setActiveAnimation(false)
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  useEffect(() => {
  authService.getStatus2fa()
    .then((res) => {
      setGoogleAuth(res.data.otp_enable)
    })
    .catch((e) => {
      console.log('err get google auth', e?.response?.detail);
      // removeItemFromLs('userToken')
      // window.location.reload()
    })
    .finally(() => {
      setPageLoading(false)
    })
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        // if(item.slug === 'security'){

        // } else {
          setActiveTab(item.slug)
        // }
      }
    })
  },[location])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("MY_PROFILE"),
      slug:'my-profile',
      id:'1'
    },
    {
      title:t("SECURITY"),
      slug:'security',
      id:'2'
    },
    {
      title:t("PERSONALIZATION"),
      slug:'personalization',
      id:'3'
    },
    {
      title:t("API_ACCESS"),
      slug:'api-access',
      id:'4'
    },
  ])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <InstanceName>
        {t("ACCOUNT_SETTINGS")}
      </InstanceName>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            blockNavigate
            location={activeTab}
            onChange={(e) => {
              if(e === 'security'){
                // openModal('confirm-password')
                navigate(`${e}`)
              } else {
                navigate(`${e}`)
              }
            }}
          />
        </div>
      </NavWrapper>

      <Routes>
        <Route path="/" element={<Navigate replace to="my-profile" />} /> 
        <Route path='/my-profile' element={<MyProfile/>}/>
        <Route path='/personalization' element={<Personalization/>}/>
        <Route path='/security' element={<Security/>}/>
        <Route path='/api-access' element={<ApiAccessPage/>}/>
      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
        instance={{google:googleAuth}}
      />
    </Container>
  )
}

export default AccountSettings