import React, { FC } from 'react'
import {Container, StyledCheckBox, CheckBoxImg} from './styles'
import CheckIcon from './check.svg'
import Label from '../label'
type CheckBoxProps = {
  title:string|any,
  value:boolean|string,
  onChange:() => void,
  disabled?:boolean,
  customStyles?:any,
  toolTip?:boolean,
  withoutText?:boolean,
  toolTipText?:string,
  inYup?:boolean,
  test_id?:string,
}

const CheckBox:FC<CheckBoxProps> = (props) => {
  const {
    title,
    value,
    onChange,
    disabled,
    customStyles,
    toolTip=false,
    toolTipText,
    inYup,
    test_id
  } = props
  
  return(
    <Container data-test-id={test_id} customStyles={customStyles}>
      {(inYup ? value === 'true' : value) ? 
        <CheckBoxImg onClick={onChange} src={CheckIcon}/>
      :
        <StyledCheckBox disabled={disabled} onClick={onChange} />
      }
      {title.length === 0 ? null : <Label toolTip={toolTip} title={title} tooltipText={toolTipText} margin='10px' />} 
    </Container>
  )
}

export default CheckBox