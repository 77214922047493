import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { UIContextProvider } from './context/ui.context';
import { AuthProvider } from './context/user.context';
import {InstanceContextProvider} from './context/instanceContext'
import { CookiesProvider } from 'react-cookie';

import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <UIContextProvider>
        <CookiesProvider>
          <AuthProvider>
            <InstanceContextProvider>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <App />
              </QueryParamProvider>
            </InstanceContextProvider>
          </AuthProvider>
        </CookiesProvider>
      </UIContextProvider>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
