import React, { useEffect, useState } from 'react';
import {ThemeProvider, createGlobalStyle} from 'styled-components'
import RootRouter from './routes';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './i18n';
import { useTranslation } from 'react-i18next';
// import "@fontsource/inter/variable.css";
import theme from './themes'
import { useUi } from './context/ui.context';
import {ModalVariables, TopProgress} from './components'
import { authService, clientService, quotasService, setClientToken } from './api';
import { useLocalStorage } from './hooks/useLocalStorage';
import { useAuth } from './context/user.context';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouterContextProvider } from './context/routerContext';
import 'react-loading-skeleton/dist/skeleton.css'
import config from './config';
import keycloak from './keycloak'
import ApiClient from './api/apiClient';
import { queries } from '@testing-library/react';
import {Helmet} from "react-helmet";
import { useQueryParam, StringParam, NumberParam, ArrayParam } from 'use-query-params';
const GlobalStyle = createGlobalStyle<any>`
  body{
    background-color: ${({theme}) => theme.base.white};
    overflow-y: scroll;
    overflow-x: hidden !important;
    ::-webkit-scrollbar {
      width: 8px;
      height:20px;
    }
    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
      border-width: 0 2px 0 2px;
      border-color: rgba(28,28,28,0);
      border-style: solid;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-track {
      margin: 0 0 0 0;
    }
    ::-webkit-scrollbar-button{
      display: none;
    }
  }
`

function App() {
  const [t] = useTranslation();
  const {
    themeDark,
    viewAlert,
    viewModalComplete,
    setViewModalComplete,
    setActiveAnimationComplete,
    activeAnimationComplete,
  } = useUi()
  const navigate = useNavigate()
  const [appLoading, setAppLoading] = useState<boolean>(true)
  const [goNext, setGoNext] = useState<boolean>(false)
  const {getDataToLs, setDataToLs} = useLocalStorage()
  const {
    setUser,
    user,
    appMenu,
    setAppMenu,
    techWork,
    setTechWork,
    setNetworkErr,
  } = useAuth()
  // const [ref, setRef] = useQueryParam('ref', StringParam);
  // const [cookies, setCookie, removeCookie] = useCookies(['refCode']);
  // console.log('cookies', cookies);
  // useEffect(() => {
  //   if(ref){
  //     setCookie('refCode', ref)
  //   }
  // },[ref])
  // useEffect(() => {
  //   if(cookies?.refCode){
  //     console.log('pidor', cookies);
  //   }
  // },[cookies])
  const [showChild, setShowChild] = useState(false)
  const updateData = async () => {
      authService.getUser()
      .then((res) => {
        if(!res.data.user){
          keycloak.logout()
        }
        if(res.data.user){
          setUser(res.data)
          if(res.data.user.active_client){
            if(localStorage.getItem('lang') !== res.data.user.language){
              localStorage.setItem('lang', res.data.user.language)
              window.location.reload()
            }
          }
          if(!res.data.user.active_client){
            navigate('/complete-registration')
          }
          setGoNext(true)
          setAppLoading(false)
        } else {
          setAppLoading(false)
          setGoNext(true)
        }
  
      })
      .catch((e) => {
        if(e.code === "ERR_NETWORK"){
          setTechWork(true)
          setNetworkErr(true)
          const interval = setInterval(() => {
            authService.getUser()
            .then((res) => {
              clearInterval(interval)
              setTechWork(false)
              setNetworkErr(false)
              if(!res.data.user){
                keycloak.logout()
              }
              if(res.data.user){
                setUser(res.data)
                if(res.data.user.active_client){
                  if(localStorage.getItem('lang') !== res.data.user.language){
                    localStorage.setItem('lang', res.data.user.language)
                    window.location.reload()
                  }
                }
                if(!res.data.user.active_client){
                  navigate('/complete-registration')
                }
                setGoNext(true)
                setAppLoading(false)
              } else {
                setAppLoading(false)
                setGoNext(true)
              }
            })
            .catch((err:any) => {
              
            })
          },3000)
        }
        if(e?.response?.data?.detail){
          viewAlert({
            severity:'error',
            message:e?.response?.data?.detail,
          })
        }
        // keycloak.logout()
        // setTechWork(true)
        setAppLoading(false)
        setGoNext(true)
      })
      // await setAppMenu(appmenu.data.menu.reduce((menuItem:any) => {
      //   menuItem.dynamicField = 'Значение для ' + obj.name;
      //   return obj;
      // }))
      

  }
  useEffect(() => {
    keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
      
    })
    .then(async(authenticated) => {
      console.log('authenticated',keycloak);
      if (authenticated) {
        //@ts-ignore
        // setClientToken(keycloak?.token)
        
        await ApiClient.interceptors.request.use((config:any) => {
          config.headers = {
            Authorization: 'Bearer ' + keycloak.token,
            'Accept-Language':`${localStorage.getItem('lang')}`
          }
          return config
        }, (error) => {
          return Promise.reject(error)
        })
        await updateData()
      } else {
        console.log('User is not authenticated!');
      }
    })
    .catch((error) => {
      console.error('Keycloak initialization error:', error);
    });
    keycloak.onTokenExpired = () => {
      keycloak?.updateToken(170)
    }
    setShowChild(true)
    // updateData()
    // const interval = setInterval(() => {
    //   keycloak?.updateToken(240)
    // },240000)
    // return () => {
    //   clearInterval(interval)
    // }
  },[])
  const [ref, setRef] = useQueryParam('ref', StringParam);
  const [cookies, setCookie, removeCookie] = useCookies(['refCode']);
  useEffect(() => {
    if(ref){
      setCookie('refCode', ref)
    }
  },[ref])
  if (!showChild) {
    return null
  }

  return (
      <>
        <TopProgress loading={appLoading}/>
        <title>{t("PERSONAL_AREA_TITLE")}</title>
        <RouterContextProvider>
          <ThemeProvider theme={theme(themeDark)}>
            <GlobalStyle theme={theme(themeDark)}/>
            {goNext ? 
              <RootRouter isAuth={false}/>
            : null}
          </ThemeProvider>
        </RouterContextProvider>
      </>
  );
}

export default App;
