import styled from "styled-components";
import { BodySmall, HeadLine3 } from "../../../../components/typography/styled";
type PieProps = {
  transform?:any,
  used?:any,
}
export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top:20px;
  margin-right:10px;
  flex-wrap: wrap;
  gap:10px;

`

export const DiagramWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* margin-right:10px;
  margin-bottom:10px; */
  padding: 28px 15px;
  max-width: 100%;
/* max-width: 400px; */
  flex-grow:1;
  flex-basis:0;
  background-color: ${({theme}) => theme.palette[10]};
  border:${({theme}) => `1px solid ${theme.palette[20]}`};
  border-radius:8px;
  /* &:hover {
    cursor: pointer;
    background: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};
  } */
  /* &:active{
    background-color: ${({theme}) => theme.isDark ? theme.palette[400] : theme.grey[200]};
    transition: 0.1s background-color ease-in-out;
  } */
`

export const DiagramName = styled(HeadLine3)`
  color:${({theme}) => theme.palette[800]};
  margin-top:11px;
`
export const Description = styled(BodySmall)`
  color:${({theme}) => theme.palette[600]};
  margin-top:6px;
`

export const Pie = styled.div<PieProps>`
  border-radius : 4px;
  width: 82px;
  height: 82px;
  aspect-ratio: 1;
  display: inline-grid;
  place-content: center;
  margin: 5px;
  border-radius: 50%;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  position: relative;
  background: ${({theme}) => {
    return theme.isDark ? `linear-gradient(180deg, #535353 0%, #747474 100%);` : `linear-gradient(180deg, #BDCEEE 0%, #F0F5FF 100%)`
  }};
  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    background: ${({used, theme}) => `conic-gradient(${theme.isDark ? '#6288FF' : '#3364FC'} ${used + '%'}, #0000 0)`};
    transition: 0s all ease-in-out;
  }
`

export const PieBorder = styled.div<PieProps>`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: 39px;
    background: ${({theme}) => theme.base.white};
    border-bottom-left-radius:4px;
    border-bottom-right-radius: 4px;
    border: ${({theme}) => `2px solid ${theme.palette[10]}`};
    &:before{
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      transform: ${({transform}) => `translate(-50%, -50%) rotateZ(${transform + 'deg'})`} ;
      transform-origin: center;
      height: 83px;
      width: 4px;
      background: ${({theme}) => theme.base.white};
      background: ${({theme}) => `linear-gradient(180deg, rgba(${theme.rgb},1) 0%, rgba(${theme.rgb},1) 49%, rgba(${theme.rgb},0) 50%)`};
    }
`