import React, { FC } from "react";
import {
  Container,
  ContainerTitle,
  ServicesWrapper,
  ServiceItem,
  IconService,
  TitleWrapper,
  ServiceTitle,
  ServiceSubTitle,
  SoonText,
} from "./styles";
import { useUi } from "../../../../context/ui.context";
import { useTranslation } from "react-i18next";
import ImagesHoverIcon from '../../../../components/side-bar/icons/images-hover.svg'
import SSHKeysHoverIcon from '../../../../components/side-bar/icons/ssh-keys-hover.svg'
import NetworksHoverIcon from '../../../../components/side-bar/icons/security-group-hover.svg'
import BalancersHoverIcon from '../../../../components/side-bar/icons/load-balancer-hover.svg'
import FloatingHoverIcon from '../../../../components/side-bar/icons/floating-ip-hover.svg'
import RouterHoverIcon from '../../../../components/side-bar/icons/router-hover.svg'

import ImagesIcon from '../../../../components/side-bar/icons/images-default.svg'
import SSHKeysIcon from '../../../../components/side-bar/icons/ssh-keys-default.svg'
import NetworksIcon from '../../../../components/side-bar/icons/security-group-default.svg'
import BalancersIcon from '../../../../components/side-bar/icons/load-balancer-default.svg'
import FloatingIcon from '../../../../components/side-bar/icons/floating-ip-default.svg'
import RouterIcon from '../../../../components/side-bar/icons/router-default.svg'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/user.context";
type ItemProp = {
  item:any
}

const Services:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const {user, quotas} = useAuth()
  const {
    setViewModalComplete,
    setActiveAnimationComplete,
    viewAlert,
  } = useUi()
  const _createFuncImage = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else {
      navigate('/images/create')
    }
  }
  const _createFuncRouter = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas.network.router.limit === quotas.network.router.in_use){
      viewAlert({
        severity:'error',
        message:`${t('ROUTER_QUOTA_EXCEEDED')}`,
      })
    } else {
      // openModal('create-router')
      navigate('/routers')
    }
  }
  const _createFuncFloating = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas?.network?.floatingip?.limit === quotas?.network?.floatingip?.in_use){
      viewAlert({
        severity:'error',
        message:`${t('FLOATING_IP_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('/floating-ips/create')
    }
  }
  const _createFuncSecurity = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas.network.security_group.limit === quotas.network.security_group.in_use){
      viewAlert({
        severity:'error',
        message:`${t('SECURITY_GROUP_QUOTA_EXCEEDED')}`,
      })
    } else {
      navigate('/security-groups/create')
    }
  }
  const createFuncNav = async (route:string) => {
    navigate(route)
  }
  const data = [
    {
      title:t("CREATE_SECURITY_GROUP"),
      id:'1',
      content:t("CREATE_SECURITY_GROUP_DESCRIPTION"),
      // navigate:'/networks/create',
      nav:() => _createFuncSecurity(),
      icon:NetworksHoverIcon,
      dark:NetworksIcon
    },
    {
      title:t("CREATE_IMAGE"),
      id:'2',
      content:t("CREATE_IMAGE_DESCRIPTION"),
      // navigate:'/images/create',
      nav:() => _createFuncImage(),
      icon:ImagesHoverIcon,
      dark:ImagesIcon
    },
    {
      title:t("CREATE_ROUTER"),
      id:'3',
      content:t("CREATE_ROUTER_DESCRIPTION"),
      // navigate:'/routers',
      nav:() => _createFuncRouter(),
      icon:RouterHoverIcon,
      dark:RouterIcon
    },
    {
      title:t("IMPORT_SSH"),
      id:'4',
      content:t("IMPORT_SSH_DESCRIPTION"),
      // navigate:'/ssh-keys/create',
      nav:() => createFuncNav('/ssh-keys/create'),
      icon:SSHKeysHoverIcon,
      dark:SSHKeysIcon
    },
    {
      title:t("SPIN_UP_A_LOAD_BALANCER"),
      id:'5',
      content:t("SPIN_UP_A_LOAD_BALANCER_DESCRIPTION"),
      // disabled:true,
      // navigate:'/load-balancers/create',
      nav:() => createFuncNav('/load-balancers/create'),
      icon:BalancersHoverIcon,
      dark:BalancersIcon
    },
    {
      title:t("CREATE_FLOATING_IP"),
      id:'6',
      content:t("CREATE_FLOATING_IP_DESCRIPTION"),
      // navigate:'/floating-ips/create',
      nav:() => _createFuncFloating(),
      icon:FloatingHoverIcon,
      dark:FloatingIcon
    },
  ]
  return (
    <Container>
      <ContainerTitle>
        {t("OTHER_SERVICES")}
      </ContainerTitle>
      <ServicesWrapper>
        {data.map((item:any) => (
          <ServiceItemView key={item.id} item={item}/>
        ))}
      </ServicesWrapper>
    </Container>
  )
}


export default Services

const ServiceItemView:FC<ItemProp> = ({item}) => {
  const {themeDark} = useUi()
  const [t] = useTranslation()
  return (
    <ServiceItem disabled={item?.disabled} onClick={item?.disabled ? null : item?.nav} key={item.id}>
      <IconService src={themeDark ? item.dark : item.icon } />
      <TitleWrapper>
        <ServiceTitle>
          <>
            {item.title}
          </>
          {item?.disabled ? <SoonText>{t("SOON")}</SoonText> : ''}
        </ServiceTitle>
        <ServiceSubTitle>
          {item.content}
        </ServiceSubTitle>
      </TitleWrapper>
    </ServiceItem>
  )
}

