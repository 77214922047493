import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { billingService } from "../../../../api";
import { Button, ModalVariables, Table, TopProgress, Typography } from "../../../../components";
import { InfoContainer, InfoTitle } from "../../../../components/info-block/styles";
import { useUi } from "../../../../context/ui.context";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";
import { formatCurrency } from "../../../../utils/display-price";
import {
  Container,
  TopBalanceWrapper,
  SubTitleText,
  InfoWrapper,
  ButtonWrapper,
  PriceTitle,
  StatusText,
  RemainingContainer,
} from './styles'
import BillingOverviewSkeleton from './skeletonOverview'
import { handleStatusName } from "../../../../utils/handle-status-name";
import { BodyDefault } from "../../../../components/typography/styled";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';


const Overview:FC = () => {
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const [modal, setModal] = useQueryParam('modal', StringParam);
  const [billingData, setBillingData] = useState<any>()
  const [invoices, setInvoices] = useState<any[]>([])
  const [invoicesPageData, setInvoicesPageData] = useState<any[]>([])
  const [services, setServices] = useState<any[]>([])
  const [servicesPageData, setServicesPageData] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [remainingInfo, setRemainingInfo] = useState<any>({})
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const [t] = useTranslation()

  const updateBillingData = () => {
    billingService.getRemainingInfo()
    .then((res) => {
      setRemainingInfo(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
    billingService.getSummary()
    .then(async(res) => {
      setBillingData(res.data)
      const invoicesData = await billingService.getInvoiceList({
        page:1,
        page_size: 10,
        ordering:'-issue_date'
      })
      setInvoices(invoicesData.data.objects.map((invoice:any) => {
        return {
          ...invoice,
          navigate:`/billing/invoices/${invoice.id}`,
          issue_date: timeConverterMMDDYYHHMM(invoice.issue_date),
          status:<StatusText status={invoice.status}>{handleStatusName(invoice.status, t)}</StatusText>,
          total:formatCurrency(invoice.total, invoice.currency),
        }
      }))
      setInvoicesPageData(invoicesData.data.count)
      const servicesData = await billingService.getServices({
        page:1,
        page_size: 10,
      })
      setServices(servicesData.data.objects.map((service:any) => {
        return {
          ...service,
          name:service.product.name,
          navigate:`/billing/services/${service.id}`,
          status:<StatusText status={service.status}>{handleStatusName(service.status, t)}</StatusText>,
          created_at:timeConverterMMDDYYHHMM(service.created_at),
          paid_until:timeConverterMMDDYYHHMM(service.paid_until),
        }
      }))
      setServicesPageData(servicesData.data.count)
      await setPageLoading(false)
    })
    
  }
  useEffect(() => {
    if(modal === 'add_payment'){
      openModal('add-credit')
      setModal(null)
    }
  },[modal])
  useEffect(() => {
    updateBillingData()
    const interval = setInterval(() => {
      updateBillingData()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const closeModal = (props?:any) => {
    setLoading(true)
    if(modalType === 'add-credit'){
      billingService.addCreditInvoice({credit:props.amount})
      .then((res) => {
        updateBillingData()

      })
      .catch((err) => {
        if(err.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err.response.data?.detail
          })
        }
      })
      .finally(() => {
        setLoading(false)
        setViewModal(false)
        setActiveAnimation(false)
      })
    } else if (modalType === 'pay-multiple-invoices') {
      const invoices:any = []
      props.map((item:any) => item.selected ? invoices.push(item.id) : '')
      const data = {
        invoices: invoices
      }
      billingService.mergeInvoices(data)
      .then((res) => {
        setViewModal(false)
        setActiveAnimation(false)
        navigate(`/billing/invoices/${res.data.new_invoice_id}`)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
    }
  }

  const ColumnsInvoices = [
    {
      title:'ID',
      key:'id'
    },
    {
      title:t("OPERATION_STATUS"),
      key:'status'
    },
    {
      title:t("ISSUE_DATE"),
      key:'issue_date'
    },
    {
      title:t("TOTAL"),
      key:'total'
    },
  ]

  const ColumnsServices = [
    {
      title:t("NAME"),
      key:'name'
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("PAID_UNTIL"),
      key:'paid_until'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
  ]

  if(pageLoading) return <BillingOverviewSkeleton pageLoading={pageLoading}/>
  return (
    <Container>
      <TopBalanceWrapper>
        <InfoContainer style={{display:'flex', flex:'auto 1', marginBottom:'0px'}}>
          <InfoTitle style={{marginBottom:'10px'}}>
            {t("BALANCE")}
          </InfoTitle>
          <InfoWrapper>
            <SubTitleText>
            {t("UP_TO_DATE_CREDIT")}
            </SubTitleText>
              <div style={{display:'flex', alignItems:'center'}}>
                <PriceTitle isRed={billingData?.uptodate_credit < 0}>
                  {formatCurrency(billingData.uptodate_credit, billingData.billing_currency)} 
                </PriceTitle>
                {remainingInfo?.remaining_days && remainingInfo?.remaining_days <= 7 ?
                  <RemainingContainer>
                    <BodyDefault>
                      {t("REMAINING_DAYS")} {remainingInfo?.remaining_days}
                    </BodyDefault>
                  </RemainingContainer>
                : null}
              </div>
            <Button
              title={t("ADD_CREDIT")}
              onClick={() => openModal('add-credit')}
              variant={'primary'}
              size="display"
            />
          </InfoWrapper>
        </InfoContainer>
        <InfoContainer style={{display:'flex', flex:'auto 1', marginBottom:'0px'}}>
          <InfoTitle style={{marginBottom:'10px'}}>
            {t("UNPAID_INVOICES")}
          </InfoTitle>
          <InfoWrapper >
            <SubTitleText>
            {t("TOTAL_OUTSTANDING_AMOUNT")}
            </SubTitleText>
            <PriceTitle>
            {formatCurrency(billingData.invoices_details.unpaid_amount, billingData.invoices_details.unpaid_amount_currency)}
            </PriceTitle>
            <ButtonWrapper>
            {billingData.invoices_details.unpaid_amount === 0 ? null :
              <Button
                title={t("PAY_NOW")}
                onClick={() => openModal('pay-multiple-invoices')}
                variant={'primary'}
                size="display"
              />
            }
            </ButtonWrapper>
          </InfoWrapper>
        </InfoContainer>
      </TopBalanceWrapper>
      <InfoContainer style={{display:'flex', flex:'auto 1', marginBottom:'0px'}}>
        <InfoTitle style={{marginBottom:'10px'}}>
          {t("SERVICES")}
        </InfoTitle>
        <Table
          columns={ColumnsServices}
          rows={services}
          hideOther
          hideCheckbox
        />
      </InfoContainer>
      <InfoContainer>
        <InfoTitle style={{marginBottom:'10px'}}>
          {t("INVOICES")}
        </InfoTitle>
        {invoices?.length > 0 ?
          <Table
            columns={ColumnsInvoices}
            rows={invoices}
            hideOther
            hideCheckbox
          />
        :
          <Typography variant="body">
            {t("NO_INVOICES")}
          </Typography>
        }
      </InfoContainer>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={billingData?.invoices_details?.unpaid_amount_currency}
        loading={loading}
        // alertSubTitle={alertSubTitle}
      />
    </Container>
  )
}

export default Overview


