import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";

const kubernetesService = {
  getList:(queries:QueriesApi, client?:any) => ApiClient.get('cluster/', {
    // params:{
    //   page_size:queries.pageSize,
    //   page:queries.page,
    //   ordering:'-created_at',
    //   active_client:client
    // }
  }),
  checkName:(name:string) => ApiClient.post('cluster/check_name/', {cluster_name:name}),
  editRestriction:(clusterId:string, data:Object) => ApiClient.patch(`cluster/${clusterId}/`, data),
  // downloadConfigFile:(token:any) => ApiClient.get('/cluster/89284/download_config/'),
  getNodePool:(id:string) => ApiClient.get(`node-pool/${id}/`),
  deleteNodePool:(id:string) => ApiClient.delete(`node-pool/${id}/`),
  createNodePool:(data:Object) => ApiClient.post(`node-pool/`, data),
  editNodePool:(id:string, data:Object) => ApiClient.patch(`node-pool/${id}/`, data),
  createCluster:(data:any) => ApiClient.post('cluster/', data),
  getVersions:() => ApiClient.get('cluster/versions/'),
  getNetworks:() => ApiClient.get('cluster/networking/'),
  deleteCluster:(id:string) => ApiClient.delete(`cluster/${id}/`),
  getClusterById:(id:string) => ApiClient.get(`cluster/${id}/`),
  retryOperationStart:(kubernetesId:string) => ApiClient.post(`cluster/${kubernetesId}/retry/`),
  upgradeCluster:(id:string, data:Object) => ApiClient.patch(`cluster/${id}/`, data),
  getAddons:() => ApiClient.get(`cluster/addons/`),
  installAddon:(id:string|number, data:Object) => ApiClient.post(`cluster/${id}/install_addons/`, data),
}

export default kubernetesService