import React, { FC, useState } from 'react';
import {
  NetworkInfoContainer,
  IpGroupsWrapper,
  NetworkText
} from '../overview/styles'
import {StyledIcon} from './styles'
import { Button, ModalVariables, Table, TopProgress } from '../../../../../components'
import { icons } from '../../../../../icons';
import { useTranslation } from 'react-i18next';

type PortIpItemProps = {
  arrayPort:any,
  isBlack?:boolean,
  title:string,
  fieldPort:string,
  onClick:(item:any) => void,
  port:any,
}

const PortIpItem:FC<PortIpItemProps> = (props) => {
  const {
    arrayPort,
    isBlack,
    title,
    fieldPort,
    onClick,
    port
  } = props
  const [editedIp, setEditedIp] = useState<any>()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()

  const {t} = useTranslation()
  console.log('portitem', props);
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setEditedIp(null)
    setModalType('')
    setActiveAnimation(false)
  }
const closeModal = (props?:any) => {
    setViewModal(false)
    setActiveAnimation(false)
    setModalType('')
  }
  return (
    <>
      <NetworkInfoContainer>
        <NetworkText>
          {title}
        </NetworkText>
        <IpGroupsWrapper  style={{flexWrap: 'wrap'}}>
          {arrayPort.map((ip:any, index:any) => (
            <div style={{display:'flex', alignItems:'center'}}>
              <NetworkText>
                {ip[fieldPort]}
              </NetworkText>
              {fieldPort === 'floating_ip_address' &&
                <Button
                  variant='stroke'
                  size='small'
                  customStyles={{
                    color:"#4671F6",
                    height:'20px',
                    padding: '2px 4px',
                    marginLeft:'4px',
                    fontWeight:'500',
                    fontSize:'12px'
                  }}
                  onClick={() => {
                    openModal('set-ptr')
                    setEditedIp(ip)
                  }}
                  title={t("RDNS")}
                />
              }
              <Button
                variant="icon"
                size="small"
                icon={icons.More}
                isFill
                customStyles={{width:'20px', marginRight:'2px', height:'20px', marginLeft:'4px'}}
                onClick={() => onClick(ip)}
              ><StyledIcon src={icons.CloseIcon}/></Button>
              {index + 1 === arrayPort?.length ? null : ','}
            </div>
          ))}
        </IpGroupsWrapper>
      </NetworkInfoContainer>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        // loading={loading}
        // loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedIp}
      />
    </>
  )
}

export default PortIpItem