import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BodyForm,
  InputsWrapper,
  PasswordWrapper,
  WindowsAlertContainer,
  DocLinkContainer,
  LinkStyled,
  ImgDoc,
  ErrorContainer,
  ErrorText,
  WarningBlock,
  WarningText,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Input,
  Select,
  RadioButtonGroup,
  Counter,
  Button,
  ModalVariables,
  TopProgress,
  MatchPassword,
  Typography,
  MultiInput,
} from "../../../../../components";
import { useQueryParam, StringParam } from 'use-query-params';
import DonutArea from "../../../../../components/donut";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import { useAuth } from "../../../../../context/user.context";
import ContentLoader, { List } from 'react-content-loader'
import styled, { useTheme } from "styled-components";
import { useInstance } from "../../../../../context/instanceContext";
import { instanceService } from "../../../../../api";
import config from "../../../../../config";
import DocumentIcon from './document.svg'
import { BodyDefault, BodyMedium } from "../../../../../components/typography/styled";
import ErrorIc from './ic-error.svg'
import rootConfig from "../../../../../config";
import { icons } from "../../../../../icons";

const Shaped = styled.div`
  shape-outside: polygon(30% 30%, 20% 20%, 30% 100%);
`


const Configure:FC = () => {
  const {user, quotas} = useAuth()
  const {setDataToLs, getDataToLs, removeItemFromLs} = useLocalStorage()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [activeConfirm, setActiveConfirm] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<boolean>(false)
  const [typeValid, setTypeValid] = useState<any>()
  const [storageTypeLocal, setStorageType] = useState<any>()
  const [machines, setMachines] = useState<any>(1)
  const [tags, setTags] = useState<string[]>(getDataToLs({key:'instance.configure'})?.tags || [])
  const InputRef:any = useRef()

  const {
    configureData,
    setConfigureData,
    getCreateOptions,
    bootSource,
    flavors,
    regions,
    storageType,
    pageLoading,
    setNetworkData,
  } = useInstance()
  const closeModal = (isSave?:boolean) => {
    setViewModal(false)
    setActiveAnimation(false)
    if(!isSave){
      removeItemFromLs('instance.configure')
      removeItemFromLs('instance.network')
      setConfigureData(null)
      setNetworkData(null)
    }
    navigate("/instances")
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const openModal = () => {
    setViewModal(true)
    setActiveAnimation(true)
  }


  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [initialValues, setInitialValues] = useState<any>({
    name:getDataToLs({key:'instance.configure'})?.name,
    type:getDataToLs({key:'instance.configure'})?.type,
    region:getDataToLs({key:'instance.configure'})?.region||regions?.[0]?.value,
    os:getDataToLs({key:'instance.configure'})?.os,
    diskType:getDataToLs({key:'instance.configure'})?.disk || '1',
    root_password:getDataToLs({key:'instance.configure'})?.root_password || '',
    tag:getDataToLs({key:'instance.configure'})?.tag || '',
    countMachines:getDataToLs({key:'instance.configure'})?.countMachines || '1',
    storageType:'local',
    volumeSize:10,
    // root_password:''
  }) 
  const onSubmit = (values:any) => {
    setConfigureData({...values, tags:tags})
    setStep("network")
  }
  const maxCountInstances = (quotas:any, value?:any) => {
    const flavorObj = flavors.find((flav:any) => flav.id === typeValid)
    if(!flavorObj){
      return quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    }
    let maxCores = (quotas?.core?.cores?.limit - quotas?.core?.cores?.in_use) / flavorObj?.vcpus
    let maxRam = (quotas?.core?.ram?.limit - quotas?.core?.ram?.in_use) / flavorObj?.memory_mb
    let maxInstances = quotas?.core?.instances?.limit - quotas?.core?.instances?.in_use
    return Math.min(Math.floor(maxCores), Math.floor(maxRam), Math.floor(maxInstances) )
  }
  const regLetter = /^(?=.*[a-z])(?=.*[A-Z])/
  const regNumbers = /^(?=.*[0-9])/
  const regSymbols = /^(?=.*[!@#$%^&*()_=+§`~<>?])/
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t('REQUIRED_FIELD')}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      type: yup.string().required(`${t('REQUIRED_FIELD')}`),
      os: yup.string().required(`${t('REQUIRED_FIELD')}`),
      diskType: yup.string(),
      // .when("storageType", {
      //   is: (storageType:any) => storageType !== 'local',
      //   then: yup.string().required()
      // }),
      // countMachines:yup.string().required().max(),
      root_password:yup.string()
      .when("os", {
        is: (os:any) => bootSource.find((boot:any) => boot.value === os)?.os_distro !== 'windows',
        then: yup.string()
        .required(`${t('REQUIRED_FIELD')}`)
        .min(8, `${t("PASSWORD_DID_NOT_PASS")}`)
        .max(24, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regLetter, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regNumbers, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regSymbols, `${t("PASSWORD_DID_NOT_PASS")}`),
      }),
      storageType:yup.string().required(`${t('REQUIRED_FIELD')}`),
      countMachines: yup.number()
        .required(`${t('REQUIRED_FIELD')}`)
        .positive()
        .integer()
        .min(1, `${t("MIN_COUNT_NUMBER")} 1`)
        .max(maxCountInstances(quotas), `${t("MAX_COUNT_MACHINES")} ${maxCountInstances(quotas)}`),
      volumeSize: storageTypeLocal === 'local' ?
        yup.number()
        .positive()
        .integer() :
        yup.number().when("storageType", {
          is: (storageType:any) => storageType !== 'local',
          then: yup.number().required(`${t('REQUIRED_FIELD')}`)
          .positive()
          .integer()
          .min(flavors.find((flav:any) => flav.id === typeValid)?.root_gb || '10', `${t("SIZE_MUST_BE_GREATER_OR_EQUAL")} ${flavors.find((flav:any) => flav.id === typeValid)?.root_gb || '10'}GB`)
          .max((quotas?.volume?.gigabytes?.limit - quotas?.volume?.gigabytes?.in_use) / machines, `${t("SIZE_MUST_BE_LESS_THAN_OR_EQUAL")} ${Math.floor((quotas?.volume?.gigabytes?.limit - quotas?.volume?.gigabytes?.in_use) / machines)} GB`),
      }),
      tag:yup.string(),
      region:yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const changeTags = (tag:string) => {
    const founded = tags.find((item:any) => item === tag)
    if(founded || tag === '' || tag === undefined) {
      setTags((prev:any) => {
        return prev.filter((item:any) => item !== tag)
      })
    } else {
      setTags((prev) => [...prev, tag])
      handleChange('tag')('')
    }
  }
  useEffect(() => {
    setMachines(values['countMachines'])
  },[values['countMachines']])
  
  useEffect(() => {
    setInitialValues({
      ...initialValues,
      region:getDataToLs({key:'instance.configure'})?.region||regions?.[0]?.value,
    })
  },[regions])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    if(typeValid && flavors?.length > 0){
      handleChange('volumeSize')(flavors?.find((flav:any) => flav.id === typeValid)?.root_gb.toString())
    } 
  },[typeValid])
  useEffect(() => {
    setDataToLs({key:'instance.configure', data:{...values, tags:tags}})
    setConfigureData({...values, tags:tags})
  },[values])
  const theme:any = useTheme()
  useEffect(() => {
    if(!pageLoading){
      instanceService.getFlavorsAssignedToImage({
        image_id:values['os'],
        selected_client_id:user.user.active_client
      })
      .then((flavs:any) => {
        if(flavs.data.flavors.find((f:any) => f.id === values['type']) === undefined){
          handleChange('type')('')
        }
        
      })
    }
  },[values['os'], values['type']])
  // useEffect(() => {
  //   if(!activeButton){
  //     setErrors({...errors, root_password:''})
  //   }
  // },[activeConfirm])
  

  const hddDataDisk = [
    {
      part1:7.8,
      part2:18.8,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:9
    },
    {
      part1:6.8,
      part2:10.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:5
    }
  ]
  const hddOperationDisk = [
    {
      part1:6.9,
      part2:10.9,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:300
    },
    {
      part1:6.8,
      part2:8.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:150
    }
  ]
  const SsdDataDisk = [
    {
      part1:7.8,
      part2:18.8,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:9
    },
    {
      part1:6.8,
      part2:10.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:5
    }
  ]
  const SsdOperationDisk = [
    {
      part1:6.9,
      part2:10.9,
      title:t("READING"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_READING"),
      value:300
    },
    {
      part1:6.8,
      part2:8.8,
      title:t("RECORD"),
      toolTip:true,
      tooltipText:t("DONUT_TOOLTIP_WRITING"),
      value:150
    }
  ]
  useEffect(() => {
    setTypeValid(values['type'])
  },[values['type']])
  useEffect(() => {
    setStorageType(values['storageType'])
  },[values['storageType']])
  
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <ContentLoader backgroundColor={theme.palette[10]} viewBox="0 0 500 800" height={800} width={500}>
        <rect x="0" y="45" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="125" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="205" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="285" rx="4" ry="4" width="343" height="48" />
        {/* <rect x="0" y="335" rx="4" ry="4" width="543" height="308" /> */}
        <rect x="0" y="365" rx="4" ry="4" width="120" height="40" />
        <rect x="100" y="445" rx="4" ry="4" width="80" height="40" />
        <rect x="0" y="445" rx="4" ry="4" width="80" height="40" />
      </ContentLoader>
    </>
  )
  return (
    <BodyForm data-test-id='instance-configure-container'>
      <InputsWrapper>
        <Input
          required
          fieldName='name'
          title={t("NAME_OF_INSTANCE")}
          placeholder=''
          {...formikProps}
        />
        <Select
          required
          toolTip={false}
          data={flavors}
          selectedValue={values['type']}
          onChange={(id:any) => handleChange('type')(id)}
          bootImage={bootSource.find((b:any) => b.id === values['os'])}
          test_id={'instance-type-select'}
          title={t("TYPE_OF_INSTANCE")}
          placeholder={t("CHOOSE_TYPE")}
          fieldName='type'
          {...formikProps}
        />
        <Select
          required
          toolTip={false}
          data={bootSource}
          selectedValue={values['os']}
          onChange={(id:any) => handleChange('os')(id)}
          categories={[
            {name:`${t("PUBLIC_IMAGES")}`, value:'public'},
            {name:`${t("CLIENT_IMAGES")}`, value:'client'}
          ]}
          test_id={'instance-os-select'}
          title={t("OS")}
          fieldName='os'
          {...formikProps}
        />
        {bootSource.find((boot:any) => boot.value === values['os'])?.os_distro !== 'windows' ?
          <PasswordWrapper>
            <Input
              required
              title={t('ROOT_PASSWORD_LABEL')}
              fieldName='root_password'
              placeholder={t('ROOT_PASSWORD_LABEL')}
              onFocus={() => setActiveConfirm(true)}
              onFocusOut={() => setActiveConfirm(false)}
              onBlur={handleBlur}
              ref={InputRef}
              isPassword
              {...formikProps}
            />
            <MatchPassword
              setEnabled={setActiveButton}
              validateString={values['root_password']}
              active={activeConfirm}
            />
          </PasswordWrapper>
        :
          <WindowsAlertContainer>
            <Typography variant='small'>
              {t("ALERT_WINDOWS_ROOT_PASSWORD_INFO")}
            </Typography>
            <DocLinkContainer onClick={() => window.open(`${rootConfig.docs_url}control-panel/cloud-platform/instances/connection-to-instance/connection-to-windows-instance`)}>
              <ImgDoc src={DocumentIcon}/>
              <LinkStyled>
                {t("HOW_CREATE_INSTANCE_ON_WINDOWS")}
              </LinkStyled>
            </DocLinkContainer>
          </WindowsAlertContainer>
        }
        <Select
          toolTip={false}
          data={storageType}
          selectedValue={values['storageType']}
          onChange={(id:any) => handleChange('storageType')(id)}
          title={t("STORAGE_TYPE")}
          test_id={'instance-storage-select'}
          fieldName='storageType'
          {...formikProps}
        />
      </InputsWrapper>
        {values['storageType'] === 'local' ? null :
          <div style={{display:'flex', flexDirection:'column', gap:'20px', alignItems:'flex-start', marginBottom:'20px'}}>
            {/* <div style={{width: '155px'}}>
              <RadioButtonGroup
                toolTip={true}
                tooltipText={(
                  <div style={{display:'flex', flexDirection:'column', gap:'6px'}}>
                    <div style={{display:'flex', gap:'6px'}}>
                      <Shaped>
                      <BodyMedium>
                        HDD
                      </BodyMedium>
                      </Shaped>
                      <BodyDefault>
                        - {t("HDD_DESC")}
                      </BodyDefault>
                    </div>
                    <div style={{display:'flex', gap:'6px'}}>
                      <Shaped>
                      <BodyMedium>
                        SDD
                      </BodyMedium>
                      </Shaped>
                      <BodyDefault>
                        - {t("SDD_DESC")}
                      </BodyDefault>
                    </div>
                  </div>
                )}
                title={t("DISK_TYPE")}
                buttons={buttons}
                selectedButton={values['diskType']}
                onChange={(id:any) => handleChange('diskType')(id)}
              />
            </div> */}

            <div style={{display:'flex',marginBottom:'0px', gap:'20px'}}>
              <DonutArea
                title={t("BANDWIDTH")}
                subtitle={t("MB_S")}
                isLeft
                data={values['diskType'] === 1 ? hddDataDisk : SsdDataDisk}

              />
              <DonutArea
                title={t("OPERATIONS_PER_SEC")}
                subtitle={t("IOPS")}
                data={values['diskType'] === 1 ? hddOperationDisk : SsdOperationDisk}

              />

            </div>
            <Counter
              title={`${t('STORAGE')}`}
              value={values['volumeSize']}
              fieldName='volumeSize'
              toolTip={true}
              tooltipText={`${t("TOOLTIP_STORAGE")}`}
              minCount={flavors.find((flav:any) => flav.id === typeValid)?.root_gb || 10}
              hideItems={'GB'}
              {...formikProps}
              onChange={(v:any) => {
                handleChange('volumeSize')(v.toString())}}
            />
          </div>
        }
      <Counter
        title={t('NUMBER_OF_MACHINES_TO_CREATE')}
        value={values['countMachines']}
        minCount={1}
        maxCount={maxCountInstances(quotas)}
        onChange={(v:any) => {
          handleChange('countMachines')(v.toString())
        }}
        fieldName='countMachines'
        {...formikProps}
      />
      <InputsWrapper>
        {/* <WarningBlock>
          <img src={icons.Warning}/>
          <WarningText>
            {t("INFO_CREATE_TAGS")}
          </WarningText>
        </WarningBlock> */}
        <MultiInput
          toolTip={true}
          toolTipText={`${t("INFO_CREATE_TAGS")}`}
          customStyles={{paddingLeft:'35px'}}
          placeholder={t("ADD_A_TAG")}
          fieldName='tag'
          tags={tags}
          icon={icons.IconTag}
          title={t("TAGS")}
          changeTags={changeTags}
          {...formikProps}
        />
      </InputsWrapper>

      {maxCountInstances(quotas) < values['countMachines'] &&
        <ErrorContainer isError={true}>
          <img src={ErrorIc}/>
          <ErrorText>{t("ERROR_INSTANCE_CREATE_QUOTAS")}</ErrorText>
        </ErrorContainer>
      }
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          disabled={maxCountInstances(quotas) < values['countMachines']}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={openModal}
          title={t("CANCEL")}
        />
      </div>
      <ModalVariables
        modalType='unsaved'
        closeModalCancel={closeModalCancel}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
      />
    </BodyForm>
  )
}

export default Configure
