import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  PageBody,
  Form,
  Gift,
  AdditionalContainer,
  ButtonsWrapper,
  GiftMoneyContainer,
  Money,
  GiftDesc,
  PhoneWrapper
} from './styles'
import GiftIcon from './illustration.svg'
import {
  Typography,
  Input,
  Select,
  Button,
  Label,
  TopProgress,
  PhoneInput,
} from '../../components'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from "../../context/user.context";
import { affiliateService, authService, clientService } from "../../api";
import { useUi } from "../../context/ui.context";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { icons } from "../../icons";
// import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useCookies } from 'react-cookie';
import { ErrorText } from "../../components/input/styles";

const CompleteRegistration:FC = () => {
  const [t] = useTranslation()
  const {user, setUser} = useAuth()
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [phone, setPhone] = useState('');

  const {
    viewAlert,
    setViewModalComplete,
    setActiveAnimationComplete,
  } = useUi()
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState<any>({
    firstName:user?.user?.first_name,
    lastName:user?.user?.last_name,
    country:'',
    company: '',
    phone: '',
    vat_id: '',
    code: '',
  })
  const [state, setState] = useState<any>({
    timestamp: null,
    responseCount: 0,
    countdown: 30,
  });
  const [resendActive, setResendActive] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSms, setLoadingSms] = useState<boolean>(false)
  const [loadingSmsComplete, setLoadingSmsComplete] = useState<boolean>(false)
  const [sendedSms, setSendedSms] = useState<boolean>(false)
  const [pageloading, setPageLoading] = useState<boolean>(true)
  const [countries, setCountries] = useState<any[]>([])
  const [cookies, setCookie, removeCookie] = useCookies(['refCode']);

  const onSubmit = (values:any) => {
    setLoading(true)
    setTimeout(() => {
      const data = {
        address1:"Bogdana 28",
        address2:"",
        city:"Kyiv",
        company:values?.company,
        country:values.country,
        custom_fields:[],
        first_name:values.firstName,
        last_name:values.lastName,
        phone:values?.phone,
        state:"Kyivska",
        vat_id:values?.vat_id || "",
        zip_code:"00000",
        code:values.code,
      }
      clientService.createClient(data)
      .then((res) => {
        setLoadingComplete(true)
        setTimeout(() => {
          if(cookies?.refCode && cookies?.refCode?.length > 0){
            // console.log('pidor', cookies);
            affiliateService.addToReff({link:`${window.location.hostname === 'console.ocplanet.cloud' ? 'https://onecloudplanet.com' : 'https://ocp-front-dev.netengi.com'}?ref=${cookies?.refCode}`})
          }
          setLoadingComplete(false)
          setLoading(false)
          authService.getUser()
            .then((res) => {
              if(res.data.user){
                setUser(res.data)
                navigate('/home')
                setViewModalComplete(true)
                setActiveAnimationComplete(true)
                // setDataToLs({key:'userToken', data:res.data.user.fleio_token.token})
              }
            })
            .catch((e) => {
              if(e?.response?.data?.detail){
                viewAlert({
                  severity:'error',
                  message:e?.response?.data?.detail,
                })
              }
            })

        }, 1000)
      })
      .catch((e) => {
        console.log('err create client', e);
        if(e.response.data.detail){
          viewAlert({
            severity:'error',
            message:e.response.data.detail,
          })
        } else {
          setErrors(e.response.data)
        }
        setLoading(false)
      })
    },500)
  }

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setErrors,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      firstName: yup.string().required(`${t('REQUIRED_FIELD')}`),
      lastName: yup.string().required(`${t('REQUIRED_FIELD')}`),
      country: yup.string().required(`${t('REQUIRED_FIELD')}`),
      company: yup.string(),
      phone: yup.string().required(`${t('REQUIRED_FIELD')}`),
      code: localStorage.getItem('region_ocp_zone') === 'pl-1' ? yup.string() : yup.string().required(`${t('REQUIRED_FIELD')}`),
      vat_id: yup.string()
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  useEffect(() => {
    setDataToLs({key:'client.data', data:values})
  },[])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  useEffect(() => {
    clientService.getOptions()
    .then((res) => {
      const list = res.data.countries
      const index = list.findIndex((it:any) => it.value === 'UA')
      const item = list.find((it:any) => it.value === 'UA')
      list.splice(index, 1)
      list.unshift(item)
      setCountries(list)
    })
    .catch((e) => {
      console.log('err', e?.response?.data?.message);
    })
    .finally(() => {
      setPageLoading(false)
    })
  },[])
  const [selectedLang, setSelectedLang] = useState<string>(localStorage.getItem('lang') || 'uk')
  const [langs, setLangs] = useState<any[]>([
    {
      label:t("ENGLISH"),
      value:'en',
    },
    {
      label:t("UKRAINE"),
      value:'uk',
    }
  ])
  const selectLang = (e:any) => {
      let selected = langs.find((item:any) => item.value === e)
      setSelectedLang(selected.value)
      // i18n.changeLanguage(selected.value);
      localStorage.setItem('lang', selected.value)
      
      window.location.reload()
    } 

  const SendSms = () => {
    setLoadingSms(true)
    clientService.sendSms({phone_number:values.phone})
    .then((res:any) => {
      setLoadingSmsComplete(true)
      viewAlert({
        severity:'info',
        message:res?.data?.detail || t("SMS_CODE_SENT"),
      })
      setTimeout(() => {
        setLoadingSmsComplete(false)
        setLoadingSms(false)
        setSendedSms(true)
        setState((prev:any) => ({
          ...prev,
          responseCount: prev.responseCount + 1,
          timestamp: new Date().getTime(),
          countdown: 30,
        }));
      },1500)
      setResendActive(false)


    })
    .catch((err:any) => {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || t("ERROR_OCCURRED"),
      })
      setLoadingSms(false)
    })
    // .finally(() => {
    //   setLoadingSms(false)
    // })
  }
  const ReSendSms = () => {
    setLoadingSms(true)
    clientService.reSendSms({phone_number:values.phone})
    .then((res:any) => {
      viewAlert({
        severity:'info',
        message:res?.data?.detail || t("SMS_CODE_SENT"),
      })
      setState((prev:any) => ({
        ...prev,
        responseCount: prev.responseCount + 1,
        timestamp: new Date().getTime(),
        countdown: 30,
      }));
      setResendActive(false)
    })
    .catch((err:any) => {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || t("ERROR_OCCURRED"),
      })
    })
    .finally(() => {
      setLoadingSms(false)
    })
  }
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (state.timestamp !== null) {
        const currentTime = new Date().getTime();
        const elapsedTime = (currentTime - state.timestamp) / 1000;
        const remainingTime = Math.max(30 - Math.floor(elapsedTime), 0);
        
        setState((prev:any) => ({ ...prev, countdown: remainingTime }));
        console.log('Time Remaining:', remainingTime, 'seconds');

        if (remainingTime === 0) {
          // Очищаем интервал после прошествия 30 секунд
          clearInterval(countdownInterval);
          setResendActive(true)
        }
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [state.timestamp, state.responseCount]);
  return(
    <Container>
      <TopProgress loading={pageloading}/>
      {pageloading ? null : 
        <>
          <Typography variant='h1'>
            {t("COMPLETE_OF_REGISTRATION")}
          </Typography>
          <PageBody>
            <Form>
              <Input
                required
                fieldName='firstName'
                title={t("FIRST_NAME")}
                customStyles={{width:'360px'}}
                placeholder=''
                {...formikProps}
              />
              <Input
                required
                fieldName='lastName'
                title={t("LAST_NAME")}
                customStyles={{width:'360px'}}
                placeholder=''
                {...formikProps}
              />
              <Select
                required
                toolTip={true}
                tooltipText={`${t("TOOLTIP_LANG_CREATE_CLIENT")}`}
                data={langs}
                selectedValue={selectedLang}
                onChange={selectLang}
                title={t("LANGUAGE")}
                customStyles={{width:'360px'}}
              />
              <Select
                required
                toolTip={false}
                data={countries}
                selectedValue={values['country']}
                onChange={(e:string) => handleChange('country')(e)}
                title={t("COUNTRY")}
                fieldName='country'
                hasSearch
                customStyles={{width:'360px'}}
                {...formikProps}
              />
              {localStorage.getItem('region_ocp_zone') === 'pl-1' ? 
                <PhoneWrapper>
                  <Label required={true} title={t("PHONE")} toolTip={false}  />
                  <PhoneInput
                    inputStyle={{height:'40px',width:'100%'}}
                    style={{height:'40px'}}
                    defaultCountry="ua"
                    value={values['phone']}
                    onChange={(phone:any) => handleChange('phone')(phone)}
                  />
                  {/* @ts-ignore */}
                  <ErrorText>{errors['phone']}</ErrorText>
                </PhoneWrapper>
              : null}
              {localStorage.getItem('region_ocp_zone') === 'pl-1' ? null :
                <AdditionalContainer>
                  <Typography variant='h3'>
                    {t("SMS_VERIFICATION")}
                  </Typography>
                  <PhoneWrapper>
                    <Label required={true} title={t("PHONE")} toolTip={false}  />
                    <PhoneInput
                      inputStyle={{height:'40px',width:'100%'}}
                      style={{height:'40px'}}
                      defaultCountry="ua"
                      value={values['phone']}
                      onChange={(phone:any) => handleChange('phone')(phone)}
                    />
                    {/* @ts-ignore */}
                    <ErrorText>{errors['phone']}</ErrorText>
                  </PhoneWrapper>
                  <ButtonsWrapper style={{margin:'0px', width:'100%'}}>
                    {sendedSms ? 
                      <Button
                        variant="stroke"
                        size="small"
                        icon={icons.Restart}
                        // loading={loadingSms}
                        // loadingComplete={loadingSmsComplete}
                        disabled={!resendActive}
                        onClick={ReSendSms}
                        title={`${t("RESEND")} ${!resendActive ? `(${state?.countdown})` : ''}`}
                      /> 
                    :
                      <Button
                        variant="primary"
                        size="small"
                        onClick={SendSms}
                        loading={loadingSms}
                        loadingComplete={loadingSmsComplete}
                        title={t("SEND_SMS_CODE")}
                      />
                    }

                  </ButtonsWrapper>
                  <Input
                    required
                    fieldName='code'
                    title={t("VERIFICATION_CODE")}
                    placeholder=''
                    {...formikProps}
                  />
                </AdditionalContainer>
              }
              <AdditionalContainer>
                <Typography variant='h3'>
                  {t("ADDITIONAL_INFO")}
                </Typography>
                <Input
                  fieldName='company'
                  title={t("COMPANY")}
                  customStyles={{}}
                  placeholder=''
                  {...formikProps}
                />
                <Input
                  fieldName='vat_id'
                  title={t("VAT_ID")}
                  customStyles={{}}
                  placeholder=''
                  {...formikProps}
                />
                <Label thinText reverse title={t("OPTIONAL_INFO_LABEL")}/>
              </AdditionalContainer>
              <ButtonsWrapper>
                <Button
                  variant="primary"
                  size="display"
                  onClick={handleSubmit}
                  loading={loading}
                  loadingComplete={loadingComplete}
                  title={t("SAVE")}
                />
                {/* <Button
                  variant="stroke"
                  size="display"
                  onClick={() => {}}
                  title={t("CANCEL")}
                /> */}
              </ButtonsWrapper>
            </Form>
            {/* <Gift style={{backgroundImage:`url(${GiftIcon})`}}>
              <GiftMoneyContainer>
                <Money>{t("BONUS_TEXT")}</Money>
              </GiftMoneyContainer>
              <GiftDesc>{t("GIFT_DESCRIPTION")}</GiftDesc>
            </Gift> */}
          </PageBody>
        </>
      }
    </Container>
  )
}

export default CompleteRegistration