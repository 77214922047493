import React, {FC} from 'react'
import Label from '../label'
import {Container, Button} from './styles'

type ButtonProps = {
  title:string,
  value:string,
  id?:string,
  disabled?:boolean,
}

type RadioProps = {
  isSmall?:boolean,
  buttons:ButtonProps[],
  selectedButton:string,
  onChange:(value:any) => void,
  title:string
  toolTip?:boolean,
  tooltipText?:any,
}

const RadioButtonGroup:FC<RadioProps> = (props) => {
  const {
    isSmall,
    buttons,
    selectedButton,
    onChange,
    title,
    toolTip,
    tooltipText
  } = props
  return(
    <>
      <Label title={title} toolTip={toolTip} tooltipText={tooltipText} />
      <Container isSmall={isSmall}>
        {buttons.map((item:ButtonProps) => (
          <Button
            key={item.id}
            active={item.value === selectedButton}
            onClick={() => onChange(item.value)}
            isSmall={isSmall}
            disabled={item?.disabled}
          >
            {item.title}
          </Button>
        ))}
      </Container>
    </>
  )
} 

export default RadioButtonGroup