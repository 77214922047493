import styled from 'styled-components'
import { BodySmall } from '../typography/styled'

type SizeProps = {
  isSmall?: boolean,
  active?:boolean,
  disabled?:boolean,
}

export const Container = styled.div<SizeProps>`
  display: flex;
  height: ${({isSmall}) => isSmall ? '32px' : '40px'};
  background-color: ${({theme}) => theme.palette[200]};
  border-radius: 4px;
  margin-top: 4px;
  box-sizing: border-box;
  padding: 2px;
  /* width:200px; */
  /* align-items: center; */

`

export const Button = styled(BodySmall)<SizeProps>`
  box-sizing: border-box;
  display: flex;
  flex:auto 1;
  border-radius: 4px;
  justify-content: center;
  /* height: 36px; */
  opacity: ${({disabled}) => disabled ? '0.5' : ''};
  padding: ${({isSmall}) => isSmall ? '6px 11px' : '10px 23px'};
  align-items: center;
  background-color: ${({theme, active}) => active ? theme.isDark ? `${theme.palette[600]}` : `${theme.palette[10]}` : ``};
  box-shadow: ${({active}) => active ? '0px 1px 2px rgba(0, 0, 0, 0.1)' : ''};
  color:${({active, theme}) => active ? `${theme.primary[600]}` : `${theme.palette[650]}`};
  white-space: nowrap;
  &:hover{
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    /* cursor:d */
  }
  transition:0.3s all ease-in-out;
`