import React, { FC, useEffect, useRef, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
  PasswordWrapper,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Select from "../../select";
import { HeadLine3 } from "../../typography/styled";
import { instanceService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import TopProgress from "../../TopBarProgress";
import Input from "../../input";
import MatchPassword from "../../password-matches";
import { handleOsIcons } from "../../../hooks/useOsIcons";
import { useInstance } from "../../../context/instanceContext";


type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  instanceId?:string;
  loading?:boolean;
  loadingComplete?:boolean;
  instance?:any;
  alertTitle?:string;
  viewModal?:boolean;
}  


const RebuildInstance:FC<ModalProps> = (props) => {
  const {
    closeModalCancel,
    closeModal,
    instanceId,
    loading,
    instance,
    alertTitle,
    viewModal,
    loadingComplete
  } = props
  const {snapshotForRebuild} = useInstance()
  const InputRef:any = useRef()
  const {viewAlert} = useUi()
  const [t] = useTranslation()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [bootSource, setBootSource] = useState<any[]>([])
  const [sshKeys, setSshKeys] = useState<any[]>([])
  const [activeConfirm, setActiveConfirm] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    image: snapshotForRebuild?.id || '',
    ssh_key:'',
    root_password:'',
  })
  const onSubmit = (values:any) => {
    const data = {
      ...values,
      ssh_keys:`[${[values.ssh_keys]}]`
    }
    closeModal(data)
  }
  useEffect(() => {
    setInitialValues({
      image: snapshotForRebuild?.id || '',
      ssh_key:'',
      root_password:'',
    })
  },[viewModal])
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      image: yup.string().required(`${t("REQUIRED_FIELD")}`),
      root_password:yup.string().required(`${t("REQUIRED_FIELD")}`),
      ssh_keys:yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const updateBootSource = () => {
    if(instanceId) {
      instanceService.getRebuildOptions(instanceId)
      .then((res:any) => {
        setBootSource([...res.data.bootSources.image.filter((im:any) => im.name.indexOf('kube') === -1).map((item:any) => {
          return {
            ...item,
            value: item.id,
            label: item.name,
            category:'public',
            icon: handleOsIcons(item.os_distro)
          }
        }), ...res.data.bootSources.owned_image.map((item:any) => {
          return {
            ...item,
            value: item.id,
            label: item.name,
            category:'client',
            icon: handleOsIcons(item.os_distro)
          }
        })])
        setSshKeys(res.data.ssh_keys.map((ssh:any) => {
          return {
            label: ssh.name,
            value: ssh.id.toString()
          }
        }))
      })
      .catch((err:any) => {
        if(err?.response?.data?.detail){
          viewAlert({
            severity: 'error',
            message: err?.response?.data?.detail
          })
        }
        closeModalCancel()
      })
      .finally(() => {
        setPageLoading(false)
      })
    }
  }
  useEffect(() => {
    updateBootSource()
  },[])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginTop:'10px', marginLeft:'10px'}}>
          {t("REBUILD_INSTANCE")} «{alertTitle}»
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>

        <Select
          // toolTip={true}
          required
          data={bootSource}
          selectedValue={values['image']}
          test_id={'instance-os-select'}
          categories={[
            {name:`${t("PUBLIC_IMAGES")}`, value:'public'},
            {name:`${t("CLIENT_IMAGES")}`, value:'client'}
          ]}
          fieldName='image'
          onChange={(e) => handleChange('image')(e)}
          {...formikProps}
          title={t("OS")}
          customStyles={{width:'360px'}}
        />
        <Select
          // toolTip={true}
          data={sshKeys}
          selectedValue={values['ssh_keys']}
          fieldName='ssh_keys'
          onChange={(e) => handleChange('ssh_keys')(e)}
          {...formikProps}
          title={t("SSH_KEY")}
          customStyles={{width:'360px'}}
        />
        <PasswordWrapper>
          <Input
            required
            title={t('ROOT_PASSWORD_LABEL')}
            fieldName='root_password'
            placeholder={t('ROOT_PASSWORD_LABEL')}
            onFocus={() => setActiveConfirm(true)}
            onFocusOut={() => setActiveConfirm(false)}
            customStyles={{width:'360px'}}
            onBlur={handleBlur}
            ref={InputRef}
            isPassword
            {...formikProps}
          />
          <MatchPassword
            setEnabled={setActiveButton}
            validateString={values['root_password']}
            active={activeConfirm}
          />
        </PasswordWrapper>
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            disabled={!activeButton}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("REBUILD")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default RebuildInstance