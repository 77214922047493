import React, { FC, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  HomePage,
  Instances,
  Volumes,
  Images,
  SSHKeys,
  Networks,
  FloatingIPs,
  SecurityGroups,
  LoadBalancers,
  AccountSettings,
  Clients,
  Notifications,
  AccessDenied,
  NotFound,
  CompleteRegistration,
  Routers,
  Operations,
  BillingPage,
  SupportPage,
  CreateTicket,
  TicketPage,
  ConfirmEmail,
  LbPool,
  LbListener,
  Kubernetes,
  CreateCluster,
  ClusterPage,
  VolumeBackups,
  BackupPage,
  Quotas,
  Affiliate,
  BareMetal,
  CreateBareMetal,
  BareMetalPage,
  ClusterWithoutAccess
} from '../pages'
import {
  CreateMember,
  CreateMonitor,
  MonitorPage,
  MemberPage,
  EditMember,
  EditPool,
 } from '../pages/lb-pool/components';
import {CreateAndEditInstance, InstancePage, } from '../pages/Instances/components'
import { SideBar, Header } from '../components';
import { useUi } from '../context/ui.context';
import {PageWrapper} from './styles'
import CreateAndEditVolume from '../pages/Volumes/create-volume';
import VolumePage from '../pages/Volumes/volume-page';
import CreateAndEditImage from '../pages/Images/create-image';
import ImagePage from '../pages/Images/image-page';
import Ports from '../pages/Ports';
import CreatePort from '../pages/Ports/create-port';
import CreateSSH from '../pages/Ssh-keys/create-page';
import CreateNetwork from '../pages/networks/create-network';
import NetworkPage from '../pages/networks/network-page';
import CreateFloatingIp from '../pages/Floating-IPs/create-page';
import CreateSecurityGroup from '../pages/Security-groups/create-page';
import GroupPage from '../pages/Security-groups/group-page';
import CreateRule from '../pages/Security-groups/group-page/create-rule';
import CreateLoadBalancer from '../pages/Load-balancers/create-page';
import ImportSSHKey from '../pages/Ssh-keys/import-page';
import ClientPage from '../pages/Clients/client-page';
import NotificationPage from '../pages/Notifications/notification-page';
import { useAuth } from '../context/user.context';
import EditClient from '../pages/Clients/client-edit';
import { useLocalStorage } from '../hooks/useLocalStorage';
import EditSSh from '../pages/Ssh-keys/edit-ssh';
import RouterPage from '../pages/Routers/router-page';
import OperationPage from '../pages/Operations/operation-page';
import EditImage from '../pages/Images/edit-image';
import { InvoicePage, ServicePage } from '../pages/Billing/components';
import { BalancerContextProvider } from '../context/balancerContext';
import EditLB from '../pages/Load-balancers/edit-page';
import BalancerPage from '../pages/Load-balancers/lb-page';
import { CreatePolicy, CreatePolicyRule, EditListener, PolicyPage, RulePolicyPage } from '../pages/lb-listener/components';
import PortPage from '../pages/Ports/port-page';
import CreateNodePool from '../pages/Kubernetes/edit-node_pool';




const AuthorizedRouter:FC = () => {
  const {smallBar} = useUi()
  
    const {user} = useAuth()
  return (
    <>

      <SideBar />
      <PageWrapper isSmall={smallBar}>
        <Header/>
        <Routes>
          <Route path="/" element={<Navigate replace to="/home" />} />
          {/* <Route path="/sign-in" element={<Navigate replace to="/home" />} /> */}
          <Route path='*' element={<NotFound />} />
          <Route path='/not-found' element={<NotFound />} />
          <Route path='/403' element={<AccessDenied />} />
          <Route path='/quotas' element={<Quotas />} />
          {user.user.active_client ? null : 
            <Route path='/complete-registration' element={<CompleteRegistration/>}/>
          }
          {/* <Route path='/confirm-email/:token' element={<ConfirmEmail />} /> */}
          {/* <Route path='/invitations/:id/:token' element={<InviteAuthPage/>}/> */}
          <Route path="/home" element={<HomePage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/support/create/*" element={<CreateTicket />} />
          <Route path="/support/:ticketId" element={<TicketPage />} />
          <Route path="/instances" element={<Instances />} />
          <Route path="/images" element={<Images />} />
          <Route path="/routers" element={<Routers />} />
          <Route path="/routers/:idRouter/*" element={<RouterPage />} />
          <Route path="/account-settings/*" element={<AccountSettings />} />
          {/* {user?.user?.access_kubernetes ?
            <> */}
              <Route path="/kubernetes" element={<Kubernetes />} />
              <Route path="/kubernetes/create" element={<CreateCluster />} />
              <Route path="/kubernetes/:clusterId/*" element={<ClusterPage />} />
              {/* <Route path="/kubernetes/:clusterId/edit_node_pool/:poolId" element={<CreateNodePool />} /> */}
              <Route path="/kubernetes/:clusterId/create_node_pool" element={<CreateNodePool />} />
            {/* </>
            :
            <>
              <Route path="/kubernetes" element={<ClusterWithoutAccess />} />
              
            </>
          } */}
          {/* bare-metal */}
          {localStorage.getItem('region_ocp_zone') === 'pl-1' ? null :
            <>
              <Route path='/bare-metal' element={<BareMetal/>}/>
              <Route path='/bare-metal/:baremetalId/*' element={<BareMetalPage/>}/>
              <Route path='/bare-metal/create' element={<CreateBareMetal/>}/>
            </>
          }
          <>
            <Route path="/load-balancers" element={<LoadBalancers />} />
            <Route path="/load-balancer-pool/:lbId/:poolId/*" element={<LbPool />} />
            <Route path="/load-balancer-pool/:lbId/:poolId/edit" element={<EditPool />} />
            {/* <Route path="/load-balancer-pool/:lbId/:poolId/*" element={<LbPool />} /> */}

            <Route path="/load-balancer-pool/:lbId/:poolId/health-monitors/create" element={<CreateMonitor />} />
            <Route path="/load-balancer-pool/:lbId/:poolId/health-monitors/:monitorId" element={<MonitorPage />} />
            <Route path="/load-balancer-pool/:lbId/:poolId/health-monitors/:monitorId/edit" element={<CreateMonitor isEdit />} />
            <Route path="/load-balancer-pool/:lbId/:poolId/members/create" element={<CreateMember />} />
            <Route path="/load-balancer-pool/:lbId/:poolId/members/:memberId/edit" element={<EditMember/>} />
            <Route path="/load-balancer-pool/:lbId/:poolId/members/:memberId" element={<MemberPage />} />


            <Route path="/load-balancer-listener/:lbId/:listenerId/*" element={<LbListener />} />
            <Route path="/load-balancer-listener/:lbId/:listenerId/edit" element={<EditListener />} />
            <Route path="/load-balancer-listener/:lbId/:listenerId/policies/:policyId/edit" element={<CreatePolicy isEdit />} />
            <Route path="/load-balancer-listener/:lbId/:listenerId/policies/:policyId/*" element={<PolicyPage />} />
            <Route path="/load-balancer-listener/:lbId/:listenerId/policies/:policyId/rules/create" element={<CreatePolicyRule />} />
            <Route path="/load-balancer-listener/:lbId/:listenerId/policies/:policyId/rules/:ruleId/edit" element={<CreatePolicyRule isEdit/>} />
            <Route path="/load-balancer-listener/:lbId/:listenerId/policies/:policyId/rules/:ruleId" element={<RulePolicyPage />} />
            
            <Route path="/load-balancer-listener/:lbId/:listenerId/policies/create" element={<CreatePolicy />} />
            <Route path="/load-balancer-listener/:lbId/:listenerId/policies/:policyId" element={<CreatePolicy />} />

            <Route path="/load-balancers/:lbId/*" element={<BalancerPage />} />
            <Route path="/load-balancers/create" element={<CreateLoadBalancer />} />
            <Route path="/load-balancers/create-pool/:lbId" element={<CreateLoadBalancer />} />
            <Route path="/load-balancers/create-listener/:lbId" element={<CreateLoadBalancer />} />
            <Route path="/load-balancers/edit/:lbId" element={<EditLB />} />
          </>
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/:notificationId" element={<NotificationPage />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:id/*" element={<ClientPage />} />
          <Route path='/clients/:id/client-edit' element={<EditClient/>}/>
          <Route path="/floating-ips" element={<FloatingIPs />} />
          <Route path="/security-groups" element={<SecurityGroups />} />
          <Route path="/security-groups/:groupId" element={<GroupPage />} />
          <Route path="/security-groups/:groupId/edit" element={<CreateSecurityGroup />} />
          <Route path="/security-groups/:groupId/create-rule" element={<CreateRule />} />
          <Route path="/security-groups/create" element={<CreateSecurityGroup />} />
          <Route path="/floating-ips/create" element={<CreateFloatingIp />} />
          <Route path="/ssh-keys" element={<SSHKeys />} />
          <Route path="/networks" element={<Networks />} />
          <Route path="/networks/edit/:networkId" element={<CreateNetwork />} />
          <Route path="/networks/:id/*" element={<NetworkPage />} />
          <Route path="/networks/create" element={<CreateNetwork />} />
          <Route path="/ssh-keys/create" element={<CreateSSH />} />
          <Route path="/ssh-keys/edit/:sshId" element={<EditSSh />} />
          <Route path="/ssh-keys/import" element={<ImportSSHKey />} />

          
          <Route path="/affiliate/*" element={<Affiliate />} />


          <Route path="/ports" element={<Ports />} />
          <Route path="/ports/:portId" element={<PortPage />} />
          <Route path="/ports/:portId/edit" element={<CreatePort />} />
          <Route path="/ports/create" element={<CreatePort />} />

          
          <Route path="/images/:imageId" element={<ImagePage />} />
          <Route path="/images/:imageId/edit/*" element={<EditImage />} />
          <Route path="/images/create" element={<CreateAndEditImage />} />
          <Route path="/volumes" element={<Volumes />} />
          <Route path="/volume-backups" element={<VolumeBackups />} />
          <Route path="/volume-backups/:backupId" element={<BackupPage />} />
          <Route path="/operations" element={<Operations />} />
          <Route path="/operations/:operationId" element={<OperationPage />} />
          <Route path="/volumes/:volumeId/*" element={<VolumePage />} />
          <Route path="/volumes/create" element={<CreateAndEditVolume />} />
          <Route path="/instances/create" element={<CreateAndEditInstance />} />
          <Route path="/instances/edit" element={<CreateAndEditInstance />} />
          <Route path="/instances/:instanceId/*" element={<InstancePage />} />
          <Route path="/billing/*" element={<BillingPage />} />
          <Route path='billing/invoices/:invoiceId' element={<InvoicePage />}/>
          <Route path='billing/services/:serviceId' element={<ServicePage />}/>
        </Routes>
      </PageWrapper>
    </>
  )
}

export default AuthorizedRouter


const InviteAuthPage:FC = () => {
  const {
    removeItemFromLs
  } = useLocalStorage()
  const {viewAlert} = useUi()
  useEffect(() => {
    viewAlert({
      severity:'error',
      message:'Important! You have been deauthorized from your account.'
    })
    setTimeout(() => {
    removeItemFromLs('userToken')

      window.location.reload()
    },4000)
  },[])
  return(
    <>
    </>
  )
}