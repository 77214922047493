import React, { FC, useState, useEffect } from "react";
import {
  Container,
  TitleBig,
  BodyWrapper,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  EstimateSubTitle,
  EstimateDesc,
  PropContainer,
  PropsWrapper,
  Divider,
  Prop
} from './styles'
import { Tabs, BackArrow, LoaderSpinner } from "../../../../components";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Configure from "./configure";
import Network from "./network";
import BackupSettings from "./backup";
import FinallyCreate from "./finally";
import { useQueryParam, StringParam } from 'use-query-params';
import { useInstance } from "../../../../context/instanceContext";
import { instanceService } from "../../../../api";
import { useAuth } from "../../../../context/user.context";
import { useTheme } from "styled-components";
import { formatCurrency, formatCurrencyString } from "../../../../utils/display-price";




const CreateAndEditInstance:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()

  const [priceBackup, setPriceBackup] = useState<any>()
  const [priceMonthBackup, setPriceBackupMonth] = useState<any>()
  const [currency, setCurrency] = useState<string>()
  const {user, quotas} = useAuth()

  const {
    getCreateOptions,
    flavors,
    bootSource,
    configureData,
    networkData,
    externalNics,
  } = useInstance()

  useEffect(() => {
    if(!step){
      setStep('configure')
    }
    if(tabs.find((item:any) => item.slug === step) === -1){
      setStep('configure')
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("CONFIGURE_INSTANCE"),
      slug:'configure',
      id:'1'
    },
    {
      title:t("NETWORK_SETTINGS"),
      slug:'network',
      id:'2'
    },
    // {
    //   title:t("BACKUP_SETTINGS"),
    //   slug:'backup',
    //   id:'3'
    // },
  ])
  console.log('networkData',networkData);
  const getCurrentPrice = () => {
    let queries:any = {
      flavorId: configureData?.type,
      region:configureData?.region,
      clientId: user?.user.active_client,
      networks: [networkData?.network],
      number_of_instances:configureData?.countMachines,
    }
    if(configureData?.storageType === '__DEFAULT__'){
      queries.volume_type = configureData?.storageType;
      queries.volume_size = configureData?.volumeSize;
    }
    if(networkData?.assignFloating){
      queries.floating_ip_network = externalNics?.[0]?.value  
    }
    if(networkData?.network){
      setPriceLoading(true)
      instanceService.getPrice(queries)
      .then((res) => {
        setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
        setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
        setCurrency(res.data.estimated_cost_display[0])
        setPriceLoading(false)
      })
    }
  }
  useEffect(() => {
    if(quotas.length > 0 && quotas?.core?.instances?.limit === quotas?.core?.instances?.in_use){
      navigate('/instances')
    }
  },[quotas])
  useEffect(() => {
    getCurrentPrice()
  },[configureData, networkData])
  useEffect(() => {
    getCreateOptions()
  },[])
  const theme:any = useTheme()
  const flavor = flavors?.find((flavor:any) => flavor.value === configureData?.type)
  // const getCurrentPriceBackup = () => {

  //   setPriceLoading(true)
  //   instanceService.getPriceInstanceBackup(instanceId)
  //   .then((res) => {
  //     setPriceBackup(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
  //     setPriceBackupMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
  //     setPriceLoading(false)
  //   })
  // }
  
  // useEffect(() => {
  //   getCurrentPriceBackup()
  // },[values])
  return(
    <Container>
      <BackArrow
        onClick={() => navigate('/instances')}
        title={t("BACK_TO_INSTANCES")}
      />
      <TitleBig>{t("CREATE_NEW_INSTANCE")}</TitleBig>
      <div style={{width:'100%'}}>
        <Tabs
          tabs={tabs}
          size='display'
          checked
          isSteps
          location={step}
        />
      </div>
      <BodyWrapper>
        {step === 'configure' ? 
          <Configure/>
        : null}
        {step === 'network' ? 
          <Network/>
        : null}
        {/* {step === 'backup' ? 
          <BackupSettings/>
          : null
        } */}
        {step === 'finally' ? 
          <FinallyCreate/>
          : null
        }
        <EstimateContainer>
          <EstimateTitle>
            {t("ESTIMATED_COST")}
          </EstimateTitle>
          {priceLoading ? 
            <LoaderSpinner customStyles={{color:theme.base.black}}/>
          :
            price ? 
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                  <EstimateSum>
                    {/* {`${currency} ${(+price * configureData?.countMachines).toFixed(2)}`} */}
                    {`${formatCurrency((+price).toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}`}
                  </EstimateSum>
                  <PerHour>{t("HOURS")}</PerHour>
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                  <EstimateSum>
                    {/* {`${currency} ${(+priceMonth * configureData?.countMachines).toFixed(2)}`} */}
                    {`${formatCurrency((+priceMonth).toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}`}
                  </EstimateSum>
                  <PerHour>{t("MONTH")}</PerHour>
                </div>
              </div>
            :
            <PerHour>
              {t("TO_DISPLAY_PRICE_SELECT_NETWORK")}
            </PerHour>
          }
          <Divider/>
          {flavor ?
            <>
              <EstimateSubTitle>
                {t("TYPE_OF_INSTANCE")}
              </EstimateSubTitle>
              <div style={{maxWidth:'200px'}}>
                <EstimateDesc>
                  {flavor.name}
                </EstimateDesc>
                <PropsWrapper>
                  <PropContainer>
                    <Prop>
                      {flavor.vcpus} CPU
                    </Prop>
                  </PropContainer>
                  <PropContainer>
                    <Prop>
                      {flavor.memory_gb} GB RAM
                    </Prop>
                  </PropContainer>
                  <PropContainer>
                    <Prop>
                      {flavor.customItem.estimate}
                    </Prop>
                  </PropContainer>
                </PropsWrapper>
              </div>
            </>
          : null}
          {/* <EstimateSubTitle>
            {t("PLACEMENT_REGION")}
          </EstimateSubTitle>
          <EstimateDesc>
            ua-central-1 
          </EstimateDesc> */}
          <PropsWrapper>
            <div style={{marginRight:'30px'}}>
              <EstimateSubTitle>
                {t("MACHINES")}
              </EstimateSubTitle>
              <EstimateDesc>
                {configureData?.countMachines}
              </EstimateDesc>
            </div>
            {(configureData?.storageType !== 'local' && configureData?.volumeSize) || flavors?.find((flavor:any) => flavor.value === configureData?.type)?.root_gb ? 
              <div>
                <EstimateSubTitle>
                  {t("STORAGE")}
                </EstimateSubTitle>
                <EstimateDesc>
                  {configureData?.storageType === 'local' ?
                  `${flavors?.find((flavor:any) => flavor.value === configureData?.type)?.root_gb || ''} GB`
                  :
                    `${configureData?.volumeSize || ''} GB`
                  }
                </EstimateDesc>
              </div>
            : null}
          </PropsWrapper>
          {bootSource?.find((boot:any) => boot.value === configureData?.os)?.label ? 
            <>
              <EstimateSubTitle>
                {t("OS")}
              </EstimateSubTitle>
              <EstimateDesc>
                {bootSource?.find((boot:any) => boot.value === configureData?.os)?.label}
              </EstimateDesc>
            </>
          : null}
          {/* <EstimateSubTitle>
            {t("DISK_TYPE")}
          </EstimateSubTitle>
          <EstimateDesc>
            {}
            Ubuntu 18.04
          </EstimateDesc> */}
        </EstimateContainer>
      </BodyWrapper>
    </Container>
  )
}

export default CreateAndEditInstance